<template>
    <remote-js v-for="remoteJs in listRemoteJs" :src="remoteJs"></remote-js>
    <van-config-provider :theme-vars="themeVars">
    <div id="app1">
        <!-- <router-link to="/">Home</router-link> | -->
        <!-- <router-link to="/about">About</router-link> -->
        <router-view v-if="loaded" class="router-view" v-slot="{ Component }">
            <transition :name="transitionName">
                <component :is="Component"/>
            </transition>
        </router-view>
    </div>
    </van-config-provider>
</template>

<script>
    import { reactive } from 'vue';
    import RemoteJs from "./common/utils/remoteJs";
    import {REMOTE_RESOURCE_DOMAIN} from "./config/app";
    import {listTodaySellerGoodsCount} from "./api/goods";
    import goodsCarApi from "./api/goodsCar";
    import Cookies from "js-cookie"

    export default {
        components: {RemoteJs},
        data () {

            return {
                loaded: false,
                listRemoteJs: [],
                todaySellerGoodsCount: [], //  商家今日下单商品统计
                goodsCartData: {}, // 购物车数据
            };
        },
        provide () {

            return {
                pv_app_todaySellerGoodsCount: reactive(this.todaySellerGoodsCount),
                pv_app_goodsCartData: reactive(this.goodsCartData),
            }
        },
        setup() {

            // themeVars 内的值会被转换成对应 CSS 变量
            // 比如 sliderBarHeight 会转换成 `--van-slider-bar-height`
            const themeVars = {
                stepperButtonRoundThemeColor: "#FFB71C",
            };

            return {
                themeVars,
            };
        },
        beforeCreate () {

            this.$watch(
                () => this.$route.params,
                (toParams, previousParams) => {

                    // 对路由变化做出响应...

                    // 引入远程js
                    this.listRemoteJs = [
                        REMOTE_RESOURCE_DOMAIN + "/resdata/" + toParams.cityname + "/systemenv.js",
                        REMOTE_RESOURCE_DOMAIN + "/cachenasdir/resdata/streetaddressweb.js",
                    ];
                }
            );
        },
        beforeUpdate() {

            this.$nextTick(() => {

                this.loaded = true;
                console.log("loaded",this.$route.query)
                if(this.$route.query.invitecode)
                {
                  console.log("invitecode",this.$route.query.invitecode)
                  Cookies.set('invitecode', this.$route.query.invitecode, { expires: 7 });
                }
                if (this.$store.getters.isLogin && this.$store.getters.isChannelPath == 0) {

                    // 商家今日下单商品统计数据
                    listTodaySellerGoodsCount().then((res) => {

                        this.todaySellerGoodsCount.customCover(res.todaySellerGoodsCount);
                    });

                    // 商家下单商品种类数与购物车数据
                    goodsCarApi.getGoodsCarGoodsNum().then(res => {
                        this.$store.commit("user/SET_CARTNUM", res.goodsNum);
                        this.goodsCartData.value = res.goodsCartData;
                    }).catch(res => {});
                }
            })
        },
        methods: {},
    };
</script>

<style lang="less">
    @import "~@/common/style/less/common.less";

    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        // text-align: center;
        width: 10rem;
        color: #2c3e50;
        background: @backgroundColorForBase;
    }

    #nav {
        padding: 30px;

        a {
            font-weight: bold;
            color: #2c3e50;

            &.router-link-exact-active {
                color: #42b983;
            }
        }
    }

    #app {
        max-width: @maxWidth;
        min-height: 100vh;

        height: auto;
        margin: 0 auto;
        -webkit-overflow-scrolling: touch;
        background: @backgroundColor;
    }

    .slide-right-enter-active,
    .slide-right-leave-active,
    .slide-left-enter-active,
    .slide-left-leave-active {
        height: 100%;
        will-change: transform;
        transition: all 500ms;
        position: absolute;
        backface-visibility: hidden;
    }

    .slide-right-enter {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }

    .slide-right-leave-active {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }

    .slide-left-enter {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }

    .slide-left-leave-active {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }

    .van-badge--fixed {
        z-index: 1000;
    }

    .van-popup--bottom {
        width: inherit;
        left: auto;
    }
</style>
